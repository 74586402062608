const Navbar = () => {
  return (
    <div className="toolbar">
      <h2 className="sm:text-2xl text-xl title-font font-medium text-white mt-4 mb-4">
        NFTCity
      </h2>
    </div>
  );
};

export default Navbar;
