const images = [
  "./assets/0/base.png",
  "./assets/0/3 generations/0.png",
  "./assets/0/3 generations/2.png",
  "./assets/0/3 generations/1.png",
  "./assets/0/4 generations/0.png",
  "./assets/0/3 generations/3.png",
  "./assets/0/4 generations/1.png",
  "./assets/0/4 generations/2.png",
  "./assets/0/4 generations/3.png",
  "./assets/0/4 generations/4.png",
  "./assets/1/base.png",
  "./assets/1/3 generations/0.png",
  "./assets/1/3 generations/1.png",
  "./assets/1/3 generations/2.png",
  "./assets/1/3 generations/3.png",
  "./assets/1/3 generations/4.png",
  "./assets/1/4 generations/0.png",
  "./assets/1/4 generations/1.png",
  "./assets/1/4 generations/2.png",
  "./assets/1/4 generations/3.png",
  "./assets/1/4 generations/4.png",
  "./assets/1/4 generations/5.png",
  "./assets/1/4 generations/6.png",
  "./assets/1/4 generations/7.png",
  "./assets/2/base.png",
  "./assets/2/3 iterations/1.png",
  "./assets/2/3 iterations/2.png",
  "./assets/2/3 iterations/3.png",
  "./assets/2/3 iterations/5.png",
  "./assets/2/3 iterations/6.png",
  "./assets/2/3 iterations/7.png",
  "./assets/12/base.png",
  "./assets/12/1 iteration/0.png",
  "./assets/12/1 iteration/1.png",
  "./assets/12/1 iteration/2.png",
  "./assets/12/1 iteration/3.png",
  "./assets/12/1 iteration/4.png",
  "./assets/15/base.png",
  "./assets/15/2 iterations/0.png",
  "./assets/15/2 iterations/1.png",
  "./assets/15/2 iterations/2.png",
  "./assets/15/2 iterations/3.png",
  "./assets/15/2 iterations/4.png",
  "./assets/15/2 iterations/5.png",
  "./assets/15/3 iterations/4.png",
  "./assets/15/3 iterations/12.png",
  "./assets/21/2 iterations/0.png",
  "./assets/21/2 iterations/1.png",
  "./assets/21/3 iterations/0.png",
  "./assets/21/3 iterations/1.png",
  "./assets/21/3 iterations/2.png",
  "./assets/21/3 iterations/3.png",
  "./assets/21/3 iterations/4.png",
  "./assets/21/3 iterations/5.png",
  "./assets/21/3 iterations/6.png",
  "./assets/21/3 iterations/7.png",
  "./assets/21/4 iterations/0.png",
  "./assets/21/4 iterations/1.png",
];

const DisplayArt = () => {
  return (
    <section className="text-gray-400 body-font">
      <div className="container px-5 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1
            className={`sm:text-3xl text-2xl font-medium title-font mb-4 text-white`}
          >
            A Sample of Our Art
          </h1>
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="flex flex-wrap w-1/2">
              <div className="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  className="w-full object-cover h-full object-center block"
                  src={images[0]}
                />
              </div>
              <div className="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  className="w-full object-cover h-full object-center block"
                  src={images[1]}
                />
              </div>
              <div className="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  className="w-full h-full object-cover object-center block"
                  src={images[2]}
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/2">
              <div className="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  className="w-full h-full object-cover object-center block"
                  src={images[3]}
                />
              </div>
              <div className="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  className="w-full object-cover h-full object-center block"
                  src={images[4]}
                />
              </div>
              <div className="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  className="w-full object-cover h-full object-center block"
                  src={images[5]}
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-wrap md:-m-2 -m-1">
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[6]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[7]}
                  />
                </div>
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={images[8]}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={images[9]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[10]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[11]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-wrap md:-m-2 -m-1">
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[12]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[13]}
                  />
                </div>
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={images[14]}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={images[15]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[16]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[17]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-wrap md:-m-2 -m-1">
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[18]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[19]}
                  />
                </div>
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={images[20]}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={images[21]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[22]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[23]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="flex flex-wrap md:-m-2 -m-1">
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[24]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[25]}
                  />
                </div>
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={images[26]}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={images[27]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[28]}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={images[29]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DisplayArt;
