import "./App.css";
import DisplayArt from "./DisplayArt";
import Intro from "./Intro";
import Navbar from "./Navbar";
import RoadMap from "./Roadmap";
import Footer from "./Footer";

const App = () => {
  return (
    <div className={`app dark-mode blue`}>
      <Navbar />
      <Intro />
      <DisplayArt />
      <RoadMap />
      <Footer />
    </div>
  );
};

export default App;
